<template>
    <div class="mb-5">
        <div class="d-grid justify-content-center">
            <slot></slot>
        </div>
    </div>
</template>
<script>
    export default {
        name: "Slider",
        props: ['slideTitle'],
        data() {
            return {

            }
        },
    };
</script>
<style lang="css" scoped>
    .wrapper {
        max-height: max-content;
        display: flex;
        overflow-x: auto;
    }
    
    .wrapper .item {
        /* min-width: 135px; */
        height: max-content;
        /* line-height: 110px; */
        text-align: center;
        /* margin-right: 2px; */
    }
</style>