<template>
  <div>
    <div class="card product-card mb-0" @click="product_navigator(product)" >
      <div class="main-container m-1">
        <div class="img-div">
          <img :src="root_img_url + product.primeImageUrl" :class="'main-img-style img-center ' + picture_size" alt=""
           id="item-image" />
        </div>
        <div class="ameneties-div d-flex justify-content-start ml-3 mt-3">
          <div>
            <div class="category text-muted" style="height: 30px;">
              <small style="font-size: 8px;">{{ product.parent_category?.name }}</small>
            </div>
            <div class="text-bold" style="height: 50px;">
              <strong>{{  product.name }}</strong>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer p-2" style="padding: 3px;">
         <div class="row m-0 p-0">
           <div class="col-md-6 m-0 p-0"  style="overflow: auto; border-right: silver solid 1px;">
               <div style="width: 100%; font-size: small;">
                    
                <strong v-if="product.discount > 0">
                  <small>
                    {{
                      $num_format(
                        product.price - (product.price * product.discount) / 100
                      )
                    }} {{ product.currency }}
                  </small>
                  </strong>
                  <strong v-else>
                    <small>
                        {{
                        price_no_discount(product.price, product.currency)
                      }}
                    </small>  
                </strong>

               </div>
           </div>
           <div class="col-md-4 ml-3 p-0"  >
               <div class="row d-flex justify-content-center">
                   <div class="mr-4 mt-1">
                        <span style="color: silver; overflow: hidden;"  class="d-flex justify-content-center"  data-toggle="tooltip" data-placement="top"  :title=" product.ratings==-1 ? 0: product.ratings">
                                <span class="fa fa-star"></span>
                        </span>
                    </div>
                   <div class="mr-4 mt-1">
                     <div class=" d-flex justify-content-center" style="color: silver;"  data-toggle="tooltip" data-placement="top"  :title="product.entity.phone">
                               <span class="fa fa-mobile" ></span>
                     </div>   
                   </div>
                 
               </div>
           </div>
         </div>
         <!-- <ul class="footer-container">
            <li  class="ml-4" style="width: 50%;">
             
            </li>
            <li style="width: 30%" class="p-0 ">
               <ul class="footer-side-item d-flex justify-content-sm-center p-0 mt-3  ">
                
                 <li style="color: silver; overflow: hidden;"  class="d-flex justify-content-center"  data-toggle="tooltip" data-placement="top"  :title=" product.ratings==-1 ? 0: product.ratings">
                      <span class="fa fa-star"></span>
                 </li>
               </ul>
            </li>
           
            
         </ul> -->
      </div>
    </div>
  </div>
</template>
<script>
// import StarRating from "../components/StarRating";

export default {
  name: "ProductCard",
  props: {
    product: {
      type: Object,
      required: true,
    },
    picture_size: {
      default: 'default-picture-size',
      required: false
    },
    btn_size: {
      default: 'normal',
      required: false
    }

  },
  components: {
    // "star-rating": StarRating
  },
  data() {
    return {
      root_img_url: process.env.VUE_APP_IMG_URL,
      discount_style: {
        color: "grey",
        textDecorationLine: "line-through",
      },
      no_discound_style: {
        fontWeight: "500",
      },
      backend_url: process.env.VUE_APP_BACKEND_URL,
    };
  },
  computed: {
  },
  methods: {
    price_no_discount: function (price, currency) {
      if (price > 0) {
        return `${currency.toLowerCase()}${this.$num_format(price)}`
      } else {
        return this.$i18n.locale === 'kin' ? 'Igiciro ntacyo' : 'Price not provided'
      }
    },
    product_navigator: function (pr) {
      this.$router.push({ name: "Product Details", params: { slug: pr.slug } })
    }
  },

};
</script>
<style lang="css" scoped>
img {
  width: 100%;
  /* height: auto; */
}

img:hover {
  filter: brightness(90%);
}

#item-image {
  max-height: 255px;
}

.product-bottom h3 {
  font: 20px;
  font-weight: 100;
}

.product-bottom h5 {
  font-size: 15px;
  padding-bottom: 10px;
}

.overlay {
  display: block;
  opacity: 0;
  position: absolute;
  top: 3%;
  margin-left: 0;
  width: 60px;
  transition: 1s ease-in-out;
}

.overlay .fa {
  cursor: pointer;
  background-color: #fff;
  color: #000;
  height: 25px;
  width: 25px;
  font-size: 14px;
  padding: 3px;
  margin: 2%;
  margin-bottom: 2%;
}

.overlay .btn-secondary {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.product-bottom .fa {
  color: gold;
}

.font-size-mobile-display {
  font-size: 20px;
}

.footer-side-item{
  /* display: inline; */
  list-style-type: none;
}

</style>
