
class DisplayManager {
   static 
   isMobile() {
        const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
        return regex.test(navigator.userAgent);
    }

    static isIpad(){
        const regex = /iPad/i;
        return regex.test(navigator.userAgent);
    }
}

export default DisplayManager;