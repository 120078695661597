<template>
  <div class="w-100">
    <nav-bar navClasses="p-0 navbar-dark">
     
      <template v-slot:nav-brand>
        <router-link class="navbar-brand" to="/">
          <img src="@/assets/images/logo2.jpeg" width="80"  class="rounded" />
        </router-link>
      </template>
      <template v-slot:nav-left-items>
        <li class="nav-item mr-2 mt-0">
          <router-link class="btn btn-outline-secondary form-control-alternative" to="About">
                {{$t('about-us')}}
          </router-link>
        </li>
        <li class="nav-item mr-2 mt-0">
          <router-link class="btn btn-outline-secondary form-control-alternative" :to="register_router">
                <span style="">{{$t('add_product')}}</span>
          </router-link>
        </li>
        <li>
           <select v-model="$i18n.locale" class="form-control form-control-alternative mr-2 mt-0  " style="width:60px;height: 40px;"  >
              <option v-for="(lang, i) in langs" :key="`Lang${i.key}`" :value="lang.val">{{ lang.val }}</option>
            </select>
        </li>
       
       
        <li class="nav-item dropdown" v-if="$store.getters.isLoggedIn">
          <a
            class="nav-link pr-0 p-0"
            href="#"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div class="media align-items-center">
              <span class="avatar avatar-sm rounded-circle" style="width:40px;height:40px; background-color:#447bd8;">
                <strong style="color:white;font-size: 20px;">{{ names[0] }}</strong>
              </span>
             
            </div>
          </a>
          <div class="dropdown-menu dropdown-menu-right">
            <div class="dropdown-header noti-title">
              <h6 class="text-overflow m-0">Welcome! {{ names }}</h6>
            </div>
            <router-link :to="{ name: 'UserProfile' }" class="dropdown-item">
              <a href="#" class="text-default">
                <i class="ni ni-single-02"></i> <span></span>
                <span  class="ml-2">Sign-in Settings</span>
              </a>
            </router-link>

            <router-link :to="{name:'Business Detail',params:{'uuid':entity? entity.uuid:''}}">
              <a href="#!" class="dropdown-item" v-if="is_customer && !$guards.has_role('Admin')">
                <i class="ni ni-settings"></i>
                <span class="text-default">Business Settings</span>
              </a></router-link
            >
             <router-link :to="{ name: 'UserProfile' }" class="dropdown-item" v-if="!$guards.is_super_admin() && !$guards.is_Admin()">
              <a href="#" class="text-default">
                <i class="fa fa-braille"></i> <span></span>
                <span class="ml-2">My Products</span>
              </a>
            </router-link>
            <a href="javascript:(0)" @click="logout" class="dropdown-item">
              <i class="ni ni-user-run"></i>
              <span>Logout</span>
            </a>
           
          </div>
        </li>
      </template>
    </nav-bar>
   
    <div
      v-if="$route.path === '/home2'"
      class="container text-center mt-4 mb-3"
    >
    </div>
    <div class="w-100">
      <router-view v-slot="{ Component }">
        <transition name="slide-fade">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
    <div>
     <Footer />
    </div>
     
  </div>
</template>

<script>

import Carousel from "../components/Carousel";
import SlideItem from "../components/SlideItem";
import Slider from "../components/Slider";
import NavBar from "../components/NavBar";
import NavItem from "../components/NavItem";

import ProductCard from "../components/ProductCard";
import footer from "../components/footer";
import Search from "../components/Search";

export default {
  name: "Home",
  components: {
    "nav-bar": NavBar,
    "nav-item": NavItem,
    Footer: footer,
    Carousel,
    "card-category": SlideItem,
    Slider,
    ProductCard,
    Search,
  },
  computed: {
    names: function () {
      return this.$store.getters.getFirstName;
    },

    register_router:function(){
       if (this.$store.getters.isLoggedIn){
         return { name: 'New Product' }
       }else{
         return { name: 'Login' }
       }
    },
    is_customer: function () {
      if (this.$store.getters.isLoggedIn)
        if (this.$store.getters.getUserProfile.referenceName === "CUSTOMER")
          return true;
      return false;
    },
    entity:function(){
       if (this.$store.getters.isLoggedIn)
        if (this.$store.getters.getUserProfile.entity)
          return this.$store.getters.getUserProfile.entity;
      return this.$store.getters.getUserProfile.entity
    },
    cart_count: function () {
      return this.$store.getters.get_cart_count;
    },
  
  },
  data() {
    return {
      langs: [{'key':'English','val':'en'}, {'key':'Kinyarwanda','val':'kin'}] 
    };
  },
  methods: {
    logout: function () {
      this.$store.dispatch("logout");
    },
  },
  
};
</script>

<style lang="css" scoped>
@media (max-width: 768px) {
  #newfacelogo {
    height: 25px;
  }
  #unlogo {
    height: 25px;
  }
}
@media (min-width: 768px) {
  #newfacelogo {
    height: 40px;
  }
  #unlogo {
    height: 40px;
  }
}
</style>