import { productRoutes } from "./productRoutes";
import { serviceRoutes } from "./serviceRoutes";
import { industryRoutes } from "./industryRoutes";
import { entityRoutes } from "./entityRoutes";
import { userRoutes } from "./userRoutes";
import { resourceRoutes } from "./resourceRoutes";
import {businessmetaRoutes} from "@/router/DashboardRoutes/businessmetaRoutes";
import {categoriesRoutes} from "@/router/DashboardRoutes/category";



const DashboardRoutes = [{
        path: '/dashboard',
        name: 'Dashboard',
        component: () =>
            import ('../../views/DashboardViews/Dashboard.vue'),
    },
   
    {
        path: '/hello',
        name: 'Hello',
        component: () =>
            import ('../../views/Home.vue'),
    },
    ...productRoutes,
    ...entityRoutes,
    ...userRoutes,
    ...industryRoutes,
    ...serviceRoutes,
    ...resourceRoutes,
    ...businessmetaRoutes,
    ...categoriesRoutes,
    {
        path: '/coming_soon',
        name: 'Coming soon',
        component: () =>
            import ('../../views/DashboardViews/ComingSoon.vue'),
    },
    {
        path: '/403',
        name: 'Unauthorized',
        component: () =>
            import ('../../views/DashboardViews/Unauthorized.vue')
    },

]

export { DashboardRoutes }