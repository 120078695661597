const mutations = {
    mutateUserProfile: (state, payload) => {
        state.auth.userProfile = payload
    },
    mutateCredentials: (state, payload) => {
        state.auth.credentials = payload
    },
    update_cart: (state, payload) => {
        state.cart_count = payload
    },
    currentPaymentUuid: (state, payload) => {
        state.currentPaymentUuid=payload
    },
    update_current_entity_uuid:(state,payload)=>{
       state.current_entity_uuid=payload
    },
    update_current_product_uuid:(state,payload)=>{
      state.current_product_uuid=payload
    },
    update_current_search:(state,payload)=>{
        state.current_search=payload
    },
    update_ratings_data:(state,payload)=>{
        state.ratings_data=payload
    }
}

export {mutations}