<template>
  <span class="item" v-for="(category, index) in categories" :key="index" >
     <a class="btn btn-round m-1 btn-outline-light " style="white-space: nowrap"  @click="searchByCategory(category)">
         <small v-if="$i18n.locale=='kin'">{{category.kin_name}}</small> 
         <small v-else>{{category.name}}</small>
     </a>
  </span>
</template>
<script>
export default {
  name: "Category",
  emits: ['searchByCategory'],
  props: {
    categories: {
      type: Array,
      required: true
    }
  },

  data() {
    return {};
  },
  methods:{
    searchByCategory:function(category){
      this.$emit('onsearch',category)
    }
  }
};
</script>
<style lang="css" scoped>
.slideItem__card {
  max-width: 10rem;
  max-height: 12rem;
  margin-bottom: 25px;
}

.btn-outline-light:hover{
  color: white !important;
  font-weight: bold;
}


</style>