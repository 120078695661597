// import { router } from "../router";
import { store } from "../store";
const guards = {

    is_super_admin: () => {
        
        if (store.getters.getUserProfile?.is_superuser) {
            return true
        }
        return false
    },
    has_perm: (perm) => {
        if (!guards.is_super_admin()) {
            if (store.getters.getUserPermissions?.find(permision => permision === perm))
                return true
            return false
        }
        return true
    },
    has_role: (role) =>{
        if (store.getters.getUserProfile?.groups?.find(group => group.name === role))
            return true
        return false
    },
    is_Admin: () => {
        if (store.getters.getUserProfile?.groups?.find(group => group.name === process.env.VUE_APP_ADMIN_GROUPNAME))
            return true
        return false
    },
    is_Agent: () => {
        if (store.getters.getUserProfile?.groups?.find(group => group.name === process.env.VUE_APP_AGENT_GROUPNAME))
            return true
        return false
    },
    is_logged_in: () => {
        if(store.state.auth.credentials !== null){
            return true
        }
            // TODO: Check if user has verified phone number
            // if (store.getters.getUserProfile?.is_phone_verified)
            //     return true
            // else
            //     return router.push({ name: 'Verify Phone' })
        return false
    },



}

export { guards }