import { guards } from "../../guards";
import { router } from "../../router";

const categoriesRoutes = [

    {
        path: '/categories/',
        name: 'Categories',
        beforeEnter: () => (guards.is_super_admin() || guards.is_Admin()) || router.push({ name: 'Unauthorized' }),
        component: () =>
            import ('../../views/Category/Categories')
    },
    {

        path: '/categories/:uuid',
        name: 'Manage Category',
        props: true,
        beforeEnter: () => (guards.is_super_admin() || guards.is_Admin()) || router.push({ name: 'Unauthorized' }),
        component: () =>
            import ('../../views/Category/ManageCategory')
    },
    {

        path: '/categories/add/new',
        name: 'Create new Category',
        props: true,
        beforeEnter: () => (guards.is_super_admin() || guards.is_Admin()) || router.push({ name: 'Unauthorized' }),
        component: () =>
            import ('../../views/Category/NewCategory')
    }



]

export { categoriesRoutes }