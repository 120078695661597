const authRoutes = [
  
    {
        path: 'login',
        name: 'Login',
        component: () =>
            import ('../views/AuthViews/Login.vue')
    },
    {
        path: 'register',
        name: 'Register',
        component: () =>
            import ('../views/AuthViews/Register.vue')
    },
    {
        path: 'forgot_password',
        name: 'Forgot Password',
        component: () =>
            import ('../views/AuthViews/ForgotPassword.vue')
    },
    {
        path: 'verify_phone',
        name: 'Verify Phone',
        component: () =>
            import ('../views/AuthViews/VerifyPhone.vue')
    },
    {
        path: 'set_password',
        name: 'Set Password',
        component: () =>
            import ('../views/AuthViews/SetPassword.vue')
    },
    {
        path: 'account_setup/:tkn?',
        name: 'Admin Setup Account',
        component: () =>
            import ('../views/AuthViews/AdminSetupAccount.vue')
    },
    {
        
        path: 'terms_and_conditions',
        name: 'Terms-and-Conditions',
        component: () =>
            import ('../views/AuthViews/TermsAndCondition.vue')
    }
    

]

export { authRoutes }