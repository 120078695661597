import { guards } from "../../guards";
import { router } from "../../router";

const resourceRoutes = [{
        path: '/resources/create/',
        name: 'ResourceCreate',
        beforeEnter: () => (guards.is_super_admin()||guards.is_Admin()) || router.push({ name: 'Unauthorized' }),
        component: () =>
            import ('../../views/Resources/NewResource.vue')
    },
    {
        path: '/resources/',
        name: 'Resources',
        component: () =>
            import ('../../views/Resources/Resources.vue')
    },
    {
        path: '/resources/detail/:uuid/',
        name: 'ResourceDetail',
        beforeEnter: () => (guards.is_super_admin()||guards.is_Admin()) || router.push({ name: 'Unauthorized' }),
        component: () =>
            import ('../../views/Resources/ResourceDetail.vue')
    },
    {
        path: '/resources/update/:uuid/',
        name: 'updateResource',
        beforeEnter: () => (guards.is_super_admin()||guards.is_Admin()) || router.push({ name: 'Unauthorized' }),
        component: () =>
            import ('../../views/Resources/UpdateResource.vue')
    },
    {
        path: '/resources/uploadImage/:uuid?/',
        name: 'ResourceUploadImage',
        beforeEnter: () => guards.is_super_admin() || router.push({ name: 'Unauthorized' }),
        component: () =>
            import ('../../views/Resources/UploadImage.vue')
    },
    {
        path: '/resources/carousel/',
        name: 'AddCarouselContent',
        beforeEnter: () => (guards.is_super_admin()||guards.is_Admin()) || router.push({ name: 'Unauthorized' }),
        component: () =>
            import ('../../views/Resources/AddCarouselItem.vue')
    },
    {
        path: '/resources/carouselsItems/',
        name: 'CarouselItems',
        beforeEnter: () => (guards.is_super_admin()||guards.is_Admin()) || router.push({ name: 'Unauthorized' }),
        component: () =>
            import ('../../views/Resources/CarouselItems.vue')
    },
    {
        path: '/resources/carousel/:uuid/uploadImage/',
        name: 'UploadCarouselImage',
        beforeEnter: () => (guards.is_super_admin()||guards.is_Admin()) || router.push({ name: 'Unauthorized' }),
        component: () =>
            import ('../../views/Resources/AddCarouselImage.vue')
    },
    {
        path: '/resources/carousel/:uuid?/detail/',
        name: 'CarouselDetail',
        beforeEnter: () => (guards.is_super_admin()||guards.is_Admin()) || router.push({ name: 'Unauthorized' }),
        component: () =>
            import ('../../views/Resources/CarouselItemDetail.vue')
    },
    {
        path: '/resources/carousel/:uuid?/update/',
        name: 'CarouselUpdate',
        beforeEnter: () => (guards.is_super_admin()||guards.is_Admin()) || router.push({ name: 'Unauthorized' }),
        component: () =>
            import ('../../views/Resources/UpdateCarouselContent.vue')
    },

]

export { resourceRoutes }