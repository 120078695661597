<template>
  <router-view />
</template>
<script>
export default {
  created() {
    // FB.getLoginStatus();
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: rgba(255, 255, 255, 0);
}

/* Track */
::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #192b3e;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #192a3e;
}
.fade-enter-active {
  transition: opacity 0.8s ease;
}
.fade-leave-active {
  transition: opacity 0s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 0.8s ease-out;
}

.slide-fade-leave-active {
  transition: all 0s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.hover-cursor:hover {
  cursor: pointer;
}
.custom-toggle input:checked + .custom-toggle-slider:after {
  color: #e9ecef;
}
.custom-toggle input:checked + .custom-toggle-slider:before {
  background: #e9ecef;
}
.custom-toggle input:checked + .custom-toggle-slider {
  border: 1px solid #e9ecef;
}
</style>

