

const businessmetaRoutes = [

    // {
    //     path: '/about/',
    //     name: 'About',
    //     beforeEnter: () => (guards.is_super_admin()||guards.is_Admin()) || router.push({ name: 'Unauthorized' }),
    //     component: () =>
    //         import ('../../views/businessmeta/About.vue')
    // },


]

export { businessmetaRoutes }