export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwinjira"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iyandikishe"])},
  "add_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erekana igicuruzwa cyawe"])},
  "about-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abo Turibo"])},
  "about-us-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BCM ni urubuga rwashyizweho mukwerekana ibicuruzwa bishya hamwe n'ibyakoreshejwe  byamamazwa n'ibigo by'igenga n'abacuruzi mu Rwanda. Uru rubuga rufite ubushobozi bwo kwerekana ibicuruzwa na serivisi kimwe nubushishozi bwibicuruzwa birimo; izina ryibicuruzwa, Izina ryubucuruzi, Ishusho ry'igicuruzwa,cyaba gishya cyangwa cyakoreshejwe, ibigize igicurizwa cyangwa serivisi, Ahantu wagisanga, uko wavugana nanyiracyo… Uru rubuga rufite uburyo bwo gushiraho konti kubacuruzi, (hasuzumwe kandi bigenzurwe), kandi birashobora gusibwa na banyiri konti. Uru rubuga ruzafasha abacuruzi b'imbere mu gihugu kwerekana ibicuruzwa byabo na serivisi batanga, imyirondoro hamwe n’ibiciro byatanzwe hamwe nuko byaboneka. Abakiriya bemere ngutanga ibitekerezo byabo ku ibicuruzwa na serivisi kandi barashobora kugereranya ibicuruzwa bitandukanye. Uru rubaga ntago rwemerera kwishyura hagati y'abagurisha n'abakiriya kandi rizakora gusa nk'urubuga ruhuza. Uru rubuga rukoresha k'ubuntu kuba rukoresha. Kubndi bibazo mwagira cg ibisobanuro mwakenera Mwatwandira kuri imeyiri:"])},
  "sign-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Injira"])},
  "create-new-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iyandikishe"])},
  "forgot-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wibajyiwe Ijambo Ry'ibanga?"])},
  "already-have-an-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niba Wariyandikishije mbere, Injira"])},
  "reset-password-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andika emeyiri cyangwa numero za telephone wakoresheje wiyandikisha:"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohereza"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emeza"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shakisha ..."])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imeyiri"])},
  "phone-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numbero za Telefone"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ijambo Ry'ibanga"])},
  "confirm-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emeza Ijambo Ry'ibanga"])},
  "enter-your-phone-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andika numero za Telephone Yawe"])},
  "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ibicuruzwa"])},
  "igicuruzwa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Igicuruzwa"])},
  "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umucuruzi"])},
  "businesses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abacuruzi"])},
  "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ibyiciro"])},
  "make-review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icyo Ubivugaho (Igitekerezo,Gushima,Kunenga)"])},
  "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icyo abandi babivuzeho"])},
  "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uko watubona"])},
  "view-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ibindi bisobanuro"])}
}