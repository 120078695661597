<template>
    <footer  style="bottom: 0;width: 100%; background-color: #172b4d;" >
      <!-- <div class="footer-top">
        <div class="container">
          <div class="row">
           
            <div class="col-md-3 col-sm-6 col-6  md-mb-30 sm-mb-30">
              <h2 class="text-white">Quick links</h2>
              <ul class="text-white">            
                <li>
                  <a style="cursor:pointer" @click="navigateToAbout()">about us</a>
                </li>
              </ul>
            </div>
            
          </div>
        </div>
      </div> -->
      <p class="footer-bottom-text">All right reserved BCM &copy;</p>
    </footer>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods:{
    navigateToAbout:function(){
      this.$router.push({name:"About"})
    }
  }
};
</script>
<style lang="css" scoped>
.footer-top {
  padding: 90px 0px;
  background: #192b3e;
  bottom: 0;
}
/* .part-1 h2{
        letter-spacing: 3px;
        margin: 10px 0;
    } */
.part-2 h2::before {
  content: "|";
  color: #e95123;
  padding-right: 5px;
}

.part-1 ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.part-1 ul li {
  line-height: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.part-1 ul li a {
  color: white;
  text-decoration: none;
}
.part-2 ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.part-2 ul li {
  line-height: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.part-2 ul li a {
  color: white;
  text-decoration: none;
}
.part-3 ul li a {
  color: #fff;
}
.part-3 h2::before {
  content: "|";
  color: #e95123;
  padding-right: 5px;
}
.part-4 a {
  display: inline-block;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  /* background: #494848; */
  margin-right: 10px;
}
.part-4 h2::before {
  content: "|";
  color: #e95123;
  padding-right: 5px;
}
.part-4 a i {
  font-size: 18px;
  color: #fff;
  padding: 10px 10px;
}

.footer-bottom-text {
  text-align: center;
  color: white;
  background: #192b3e;
  margin-bottom: 0;
  line-height: 75px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}

@media (max-width: 767.98px) {
  .md-mb-30 {
    margin-bottom: 40px;
  }
  .sm-mb-30 {
    margin-bottom: 30px;
  }
  .footer-top {
    padding: 50px 0;
  }
}
</style>
