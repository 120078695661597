import { guards } from "../../guards";
import { router } from "../../router";
const userRoutes = [{
        path: '/user/profile',
        name: 'UserProfile',
        beforeEnter: () => guards.is_logged_in() || router.push({ name: 'Unauthorized' }),
        component: () =>
            import ('../../views/AuthViews/UserProfile.vue')
    },

]

export { userRoutes }