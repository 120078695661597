const getters = {
    getUsername: state => {
        return state.auth.userProfile.username
    },
    getUserPermissions: state => {
        return state.auth.userProfile.allperms
    },
    getToken: state => {
        return state.auth.credentials.access_token
    },
    getNames: state => {
        if(state.auth.userProfile){
            return `${state.auth.userProfile.first_name} ${state.auth.userProfile.last_name}`
        }else{
            return "CUSTOMER"
        }
        // return state.auth.userProfile.referenceName === "CUSTOMER" ? `${state.auth.userProfile.customer.firstName} ${state.auth.userProfile.customer.lastName}` : `${state.auth.userProfile.first_name} ${state.auth.userProfile.last_name}`
    },
    getFirstName: state => {
        if(state.auth.userProfile){
                return `${state.auth.userProfile.first_name}`
        }else{
            return ""
        }
    },
    getUserProfile: state => {
        return state.auth.userProfile
    },
    isLoggedIn: state => {
        return state.auth.userProfile ? true : false
    },
    get_cart_count: state => {
        return state.cart_count
    },
    get_ratings_data:state =>{
      return state.ratings_data
    },
    get_current_entity_uuid:state=>{
        return state.current_url_uuid
    },
    get_current_product_uuid:state=>{
        return state.current_product_uuid
    },
    get_current_search:state=>{
       return state.current_search
    }

}

export { getters }