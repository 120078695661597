import { createRouter, createWebHistory } from 'vue-router'
import HomeLayout from '../Layouts/HomeLayout.vue'
import { authRoutes } from './authRoutes'
import { DashboardRoutes } from './DashboardRoutes'
import { customerRoutes } from "./customerRoutes"

const routes = [{
        path: '/',
        name: 'HomeLayout',
        component: HomeLayout,
        children: [{
                path: '',
                name: 'Home',
                component: () =>
                    import ('../views/Home.vue'),
                children: [{
                    path: '/industry/:uuid?/business/public',
                    name: 'IndustryEntitiesPublic',
                    props: true,
                    component: () =>
                        import ('../components/BusinessFilter.vue'),
                }, ]
            },
            {
                path:'/about',
                name: 'About',
                component:() => import ('../views/About.vue')
            },

            {
                path: '/profile/:slug',
                name: 'Company Profile',
                component: () =>
                    import ('../views/Profile.vue')
            },
            {
                path: '/business/product/:slug/details/',
                name: 'Product Details',
                props: true,
                component: () =>
                    import ('../views/ProductDetails.vue')
            },
            {
                path: '/search/:search?',
                name: 'SearchView',
                component: () =>
                    import ('../views/SearchView.vue'),
            },

            {
                path: '/entities/public/',
                name: 'EntitiesPublic',
                props: true,
                component: () =>
                    import ('../views/Entity/EntitiesPublic.vue')
            },
          
        ]
    },

    {
        path: '/',
        name: 'AuthLayout',
        component: () =>
            import ('../Layouts/AuthLayout.vue'),
        children: [
            ...authRoutes
        ]
    },
    {
        path: '/',
        name: 'CustomerLayout',
        component: () =>
           import ('../Layouts/DashboardLayout.vue'),
        children: [
            ...customerRoutes,
            ...DashboardRoutes
        ]
    },
    {
        path: '/loading/:message',
        name: 'Loading',
        props: true,
        component: () =>
            import ('../views/LoadingScreen.vue'),
    },
    {
        path: '/privacy/policy/',
        name: 'Privacy Policy',
        component: () =>
            import ('../views/PrivacyPolicy.vue'),
    },

]

const router = createRouter({

    scrollBehavior(to) {
        if (to.name != 'IndustryEntitiesPublic') {
            return { top: 0, behavior: 'smooth' }
        } else if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
            }
        }
    },
    history: createWebHistory(),
    routes
})

export { router }