import { guards } from "../../guards";
import { router } from "../../router";

const entityRoutes = [
    {
        path: '/entity/profilePreview/:uuid?/',
        name: 'Profile Preview',
        component: () =>
            import ('../../views/Entity/ProfilePreview.vue')
    },
    {
        path: '/entities/',
        name: 'Businesses',
        beforeEnter: () => (guards.is_super_admin()||guards.is_Admin()) || router.push({ name: 'Unauthorized' }),
        component: () =>
            import ('../../views/Entity/Entities.vue')
    },
    {
        path: '/entity/new/',
        name: 'Create Business',
        beforeEnter: () => (guards.is_super_admin()||guards.is_Admin()) || router.push({ name: 'Unauthorized' }),
        component: () =>
            import ('../../views/Entity/NewEntity.vue')
    },
    {
        path: '/entity/update/:uuid?/',
        name: 'Update Business',
        beforeEnter: () => guards. is_logged_in() || router.push({ name: 'Unauthorized' }),
        component: () =>
            import ('../../views/Entity/UpdateEntity.vue')
    },
    {
        path: '/entity/uploadImages/:uuid?/',
        name: 'Entity Upload Images',
        beforeEnter: () => guards. is_logged_in() || router.push({ name: 'Unauthorized' }),
        component: () =>
            import ('../../views/Entity/UploadImages.vue')
    },
    {
        path: '/entity/detail/:uuid?/',
        name: 'Business Detail',
        beforeEnter: () => guards. is_logged_in() || router.push({ name: 'Unauthorized' }),
        component: () =>
            import ('../../views/Entity/EntityDetail.vue')
    },
    {
        path: '/entity/:uuid/contact/new/',
        name: 'New Business Contact',
        beforeEnter: () => guards. is_logged_in() || router.push({ name: 'Unauthorized' }),
        component: () =>
            import ('../../views/Entity/NewContact.vue')
    },
    {
        path: '/entity/orders/',
        name: 'Business Orders',
        beforeEnter: () => guards. is_logged_in() || router.push({ name: 'Unauthorized' }),
        component: () =>
            import ('../../views/Entity/EntityOrders.vue')
    },
   
    {
        path: '/entity/:uuid/contact/update/',
        name: 'Contact Update',
        props: true,
        beforeEnter: () => guards. is_logged_in() || router.push({ name: 'Unauthorized' }),
        component: () =>
            import ('../../views/Entity/UpdateContact.vue')
    },

    {
        path: '/business/order/:uuid/detail',
        name: 'Inquiry Detail',
        beforeEnter: () => guards. is_logged_in() || router.push({ name: 'Unauthorized' }),
        props: true,
        component: () =>
            import ('../../views/Entity/EntityInquiryDetail.vue')
    },
]

export { entityRoutes }