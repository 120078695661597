<template>
 <div id="carouselExampleControls" class="carousel slide" data-ride="carousel" style="height: 10%">
   <ol class="carousel-indicators">
     <li
         data-target="#carouselExampleIndicators"
         :class="(i===0)? 'active':''"
         v-for="(slide, i) in slides"
         :key="i"
         :data-slide-to="i"
     ></li>

   </ol>
   <div class="carousel-inner" v-if="slides.length > 0">
     <div
         :class="(i===0)? 'carousel-item active':'carousel-item'"
         v-for="(slide, i) in slides"
         :key="i"
         :link="slide.link"
     >
       <img class="d-block w-100" height="200" :src="pictureUrl(image_base_url,slide.iconImage,slide.source)" alt="First slide">
     </div>

   </div>
   <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
     <span class="fa fa-chevron-circle-left" aria-hidden="true"></span>
     <span class="sr-only">Previous</span>
   </a>
   <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
     <span class="fa fa-chevron-circle-right" aria-hidden="true"></span>
     <span class="sr-only">Next</span>
   </a>
 </div>
</template>
<script>
export default {
  components: {

  },
  name: "Carousel",
  props: {
    slides: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      backend_url: process.env.VUE_APP_BACKEND_URL,
       image_base_url:process.env.VUE_APP_IMG_URL,
      breakpoints: {
        10000: {
          slideRatio: 3 / 3,
          bulletsOutside: true,
          arrows: false,
        },
        1400: {
          slideRatio: 1.0,
          bulletsOutside: true,
          arrows: false,
        },
        1280: {
          slideRatio: 4 / 4,
          bulletsOutside: true,
        },
        1000: {
          slideRatio: 4 / 4,
        },
        900: {
          slideRatio: 3 / 3,
          bulletsOutside: true,
        },
        600: {
          slideRatio: 2 / 2,
          arrows: false,
          bulletsOutside: true,
        },
      },
    };
  },
  
  methods:{
    pictureUrl:function(rootUrl,path,source='server'){
      if(source==='local'){
        return path
      }else{
        if(path!=='undefined'){
          return rootUrl+path
        }else{
          return "@/assets/images/advert_banner.jfif"
        }
      }

    }

  },
  watch:{
  
  },
  created() {
  
  },
};
</script>
<style lang="css" scoped>

.carousel-indicators{
  bottom: -250px !important;
}

.carousel-control-prev{
  bottom: -250px !important;
}
.carousel-control-next{
  bottom: -250px !important;
}
</style>
