import { guards } from "../../guards";
import { router } from "../../router";
const industryRoutes = [{
        path: '/industries',
        name: 'Industries',
        beforeEnter: () => guards.has_perm('entity.view_industry') || router.push({ name: 'Unauthorized' }),
        component: () =>
            import ('../../views/Industries/Industries.vue')
    },
    {
        path: '/industries/new',
        name: 'New Industry',
        beforeEnter: () => guards.has_perm('entity.add_industry') || router.push({ name: 'Unauthorized' }),
        component: () =>
            import ('../../views/Industries/NewIndustry.vue')
    },
    {
        path: '/industry/:uuid/detail',
        name: 'IndustryDetail',
        beforeEnter: () => guards.has_perm('entity.view_industry') || router.push({ name: 'Unauthorized' }),
        component: () =>
            import ('../../views/Industries/IndustryDetail.vue')
    },
]

export { industryRoutes }