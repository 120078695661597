import axios from 'axios'
import qs from 'querystring'
import { alert } from '../utils/alertUtils'
import { router } from '../router'

const actions = {

    login: (context, payload) => {
        const data = {
            grant_type: "password",
            username: payload.username,
            password: payload.password,
        }
        const str = `${process.env.VUE_APP_CLIENT_ID}:${process.env.VUE_APP_CLIENT_SECRET}`
        const enc = window.btoa(str)
        const cred = qs.stringify(data)
        const url = `${process.env.VUE_APP_BACKEND_URL}/oauth/token/`
        axios(
            {
                method: 'POST',
                url: url,
                data: cred,
                headers: {
                    'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
                    'Authorization': `Basic ${enc}`
                },
            }
        ).then(res => {

            router.push({ name: 'Loading', params: { message: 'Signing you in ...' } })
            context.commit('mutateCredentials', res.data)
            context.dispatch('get_user_profile', res.data.access_token)
        }).catch(err => {
           if (err.response.error==="invalid_client"){
               alert.error("Invalid Phone or password, try again ")
           }else{
               alert.error(err.response.data.error_description || err.response || 'Login failed try again,later')
               router.push({ name: 'login'  })
           }

        })
    },

    logout: (context) => {
        const data = {
            token: context.getters.getToken,
            client_id: process.env.VUE_APP_CLIENT_ID,
            client_secret: process.env.VUE_APP_CLIENT_SECRET,
        }
        const str = `${process.env.VUE_APP_CLIENT_ID}:${process.env.VUE_APP_CLIENT_SECRET}`
        const enc = window.btoa(str)
        const cred = qs.stringify(data)
        const url = `${process.env.VUE_APP_BACKEND_URL}/oauth/revoke_token/`
        axios(
            {
                method: 'POST',
                url: url,
                data: cred,
                headers: {
                    'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
                    'Authorization': `Basic ${enc}`
                },
            }
        ).then(() => {
            context.commit('mutateCredentials', null)
            context.commit('mutateUserProfile', null)
            context.commit('update_cart', 0)
            router.push('/')
        }).catch(() => {
            alert.error("Error Occurred, try again ")
        })
    },
    get_user_profile: (context, token) => {
        axios.get(`${process.env.VUE_APP_BACKEND_URL}/api/profile/`, {
            headers: {
                'authorization': `Bearer ${token}`
            }
        }).then(res => {
            // TODO: Check if user has verified phone number
            if (res.data.has_set_password) {
                context.commit('mutateUserProfile', res.data)
                if (res.data.referenceName === 'CUSTOMER' && !res.data.is_staff) {
                    if (res.data.entity){
                        router.push(
                            {
                                name: 'Business Detail',
                                params: {
                                    uuid: res.data.entity.uuid,
                                    name:res.data.entity.name.split(" ").join("_")
                                }
                            }
                        )
                    }else{
                        router.push("/")
                    }
                } else if (res.data.referenceName === 'Entity' && !res.data.is_staff) {
                    router.push(
                        {
                            name: 'Business Detail',
                            params: {
                                uuid: res.data.entity.uuid,
                                name:res.data.entity.name.split(" ").join("_")
                            }
                        }
                    )
                } else
                    router.push('/dashboard')
            } else if (!res.data.has_set_password) {
                context.commit('mutateUserProfile', res.data)
                router.push({ name: 'Set Password'})
            }else{
                context.commit('mutateUserProfile', res.data)
                router.push({ name: 'Verify Phone'})
            }
        }).catch(() => {
            alert.error( 'Internal server error, try again later')
            router.push({ name: 'Home'  })
        })
    },
    register: (context, payload) => {
        axios.post(`${process.env.VUE_APP_BACKEND_URL}/api/signup/`, payload)
            .then(() => {
                 alert.success("Registered successfully,")
                router.push({ name: 'Login'})
            })
            .catch(err => {
                if (err.response.data.username){
                    alert.error("Please provide email or phone number")
                }else{
                    alert.error(err.response.data || err.response || err)
                }
            })
    },

}

export { actions }