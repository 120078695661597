export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "add_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish your product"])},
  "about-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
  "about-us-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BCM is an online classifieds platform for new and used products advertised by individuals and businesses in Rwanda. The platform has provisions to showcase their products and services as well as products insights including; product name, Business name, Image, new or used, features, Location, contact… The platform has provisions for account creation for vendors, (subject to review and verification), and can be deleted by the account owners. The platform will have provisions for local sellers of goods and services to list their products and services, profiles and proposed price estimates and availability. Clients to review the products and services and can rate various products. The platform does not handle payments between the sellers and the clients and will only serve as a connecting platform. The platform is free of charge for users. For any enquiries please reach out to us Through Email "])},
  "sign-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
  "create-new-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Account"])},
  "forgot-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password?"])},
  "already-have-an-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have an account, Sign in"])},
  "reset-password-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Your Email or Phone Number used during sign up:"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search ..."])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "phone-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "confirm-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
  "enter-your-phone-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Your Phone Number"])},
  "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
  "igicuruzwa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
  "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business"])},
  "businesses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Businesses"])},
  "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
  "make-review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make Review"])},
  "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews"])},
  "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
  "view-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View details"])}
}