import { guards } from "../../guards/index";
import { router } from "../index";
const productRoutes = [
    {
        path: '/products',
        name: 'Products',
        beforeEnter: () => guards.is_logged_in() || router.push({ name: 'Unauthorized' }),
        component: () => import('../../views/Products/Products.vue')
    },
    {
        path: '/products/new',
        name: 'New Product',
        beforeEnter: () => guards.is_logged_in() || router.push({ name: 'Unauthorized' }),
        component: () => import('../../views/Products/NewProduct.vue')
    },
    {
        path: '/products/update/:uuid',
        name: 'Update Product',
        beforeEnter: () => guards.is_logged_in() || router.push({ name: 'Unauthorized' }),
        component: () => import('../../views/Products/NewProduct.vue')
    },
    {
        path: '/products/:uuid/gallery',
        name: 'Product Gallery',
        beforeEnter: () => guards.is_logged_in() || router.push({ name: 'Unauthorized' }),
        component: () => import('../../views/Products/ProductGallery.vue')
    },
    {
        path: '/products/:uuid/variations',
        name: 'Product Variations',
        beforeEnter: () => guards.is_logged_in() || router.push({ name: 'Unauthorized' }),
        component: () => import('../../views/Products/ProductVariations.vue')
    },
]

export { productRoutes }