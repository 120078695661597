<template>
  
  <div class="form-group border-default bg-white shadow-lg mt-2 mb-2">
    <div class="input-group input-group-merge">
      <input
        style="width: 30vw"
        type="text"
        name="search"
        v-model="search"
        class="form-control  border-0 py-0"
        :placeholder="$t('search')"
        aria-describedby="basic-addon2"
        @keyup.enter="searchFn"
      />
      <div style="z-index: 1030;" @click="searchFn" class="input-group-append rounded-circle border-0 py-0 hover-cursor">
        <span id="basic-addon2" class="input-group-text rounded-circle border-0">
          <em class="fas text-white bg-primary rounded-circle p-2 fa-search mt--1"></em>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Search",
  data() {
    return {
      search: ""
    };
  },

  methods: {
    searchFn: function () {
      if (this.search) {
        this.$store.commit("update_current_search",this.search)
      }
    },
  },
};
</script>
<style lang="css" scoped>

</style>